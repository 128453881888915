import { useNavigate } from "react-router-dom";
import { auth } from '../Firebase';
import { Tooltip } from 'react-tooltip';

function InicioH() {
    
    const navigate = useNavigate();
    
    const logout = () => {
        navigate('/logout');
    }

    return(
        <div className="container">
            <div className="row">
                <div className = 'col-6 col-md-8 bg-secondary text-white'>
                    <span className="align-self-start">{auth.currentUser.email}</span>
                </div>
                <div className="col-1 ms-auto">
                    <div className="form-check form-switch">
                        <input className="form-check-input salir" type="checkbox" role="switch" id="logout" checked  />
                        <Tooltip anchorSelect='.salir' place='left' color='info'>Salir</Tooltip>
                    </div>
                </div>
            </div>
            <div className="row">
                <button onClick={logout}>Salir</button>
            </div>
        </div>
    )
}

export default InicioH;