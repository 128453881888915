function InicioProf () {
    return(
        <div>
            <h1 className="text-center">Gran Logia de la Masonería del Uruguay</h1>
            <h3 className="text-center">FUNDADA EL 17 DE JULIO DE 1856</h3>
            <br></br>
            <hr />
            <h2 className="text-primary">BIENVENIDO</h2>

            <p style={{ textAlign: 'justify'}}>Nos complace que desee informarse acerca de nuestra antigua Institución. La cual está presente en todos los Departamentos de nuestro País.

            Esperamos que la información desplegada en esta página, sea de utilidad para contestar muchas de las preguntas que surgen al escuchar la palabra Masonería.

            Cualquier persona puede vivir de acuerdo a nuestros universales valores. Está al alcance de todos mejorar individualmente y colaborar de ese modo a desarrollar una mejor sociedad.

            Se trata de una labor personal, que alcanza al colectivo, pues a todo nivel, en un mundo absolutamente globalizado, trasciende las culturas, las etnias y otros aspectos que, en oportunidades, nos impulsan a olvidar la igualdad implícita y fundamental de nuestra condición humana.

            Los ideales que mueven a millones de Masones en todo el Orbe, son un compromiso de vida.

            Muchos referentes de la historia de la humanidad han integrado nuestras filas, de todas las clases sociales, edades, profesiones, artes y oficios. Manteniendo sus individualidades, pusieron todo su esfuerzo al servicio de nuestros ideales superiores.

            Son esos principios de amor a la humanidad, a la verdad, a la libertad en todas sus acepciones los que nos mueven a trabajar para el progreso moral y material de todos los seres humanos, considerando que pueden hermanarse solidariamente, superando sus aparentes divergencias.

            Nuestra historia nos ha dado la esencia de Orden Iniciática, Universal y Humanista, que hoy nos impulsa a ser cada día mejores personas.
            </p>
        </div>
    )
}

export default InicioProf;