import logoGLMU from '../../images/LOGOGLMU.png';
import logoThemis from '../../images/LOGOTHEMISTRANSPARENTE.png';
import logoThemisLuto from '../../images/LOGOTHEMISLUTO.png';

function Encabezado (props) {

    const logoMostrar = (props.logo === 0 ? (<img className='img-fluid' style={{height: '90px'}} src={logoThemis} alt='Logo Themis' />) 
                                    :
                                      (<img className='img-fluid' style={{height: '100px'}} src={logoThemisLuto} alt='Logo Themis Luto' />));

    return (
        <div className='row'>
            <div className='col-6 d-none d-md-block' >
                <img className='img-fluid' src={logoGLMU} alt = 'Logo GLMU' />
            </div>
            <div className='col-4 m-auto' >
                {logoMostrar}
            </div>
        </div>
    )
}

export default Encabezado;