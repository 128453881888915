import './App.css';
import { auth } from '../Firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { useState } from 'react';
import {Routes, Route, BrowserRouter } from 'react-router-dom';
import Login from '../Login/Login';
import Logout from '../LogOut';
import Encabezado from '../Encabezado';
import MenuP from '../Menu/MenuP';
import InicioProf from '../Inicio/InicioProf';
import Masoneria from '../Masoneria/Masoneria';
import HMasoneria from '../Masoneria/HMasoneria';
import Requisitos from '../Masoneria/Requisitos';
import HThemis from '../Masoneria/HThemis';
import InicioH from '../Inicio/InicioH';

function App() {
  
  const [usuario, setUsuario] = useState('');
  const [logo, setLogo] = useState(0);

  onAuthStateChanged(auth, (user) => {
    if (user)
      {
        console.log ('usuario => ',user.uid);
        setUsuario(user.uid);
      }
    else
      {
        console.log ('No hay usuario');
        setUsuario('');
      }
  })

  if (usuario === '')
    {
      
      return ( 
        <BrowserRouter>
          <div className="App">
            <div className='container col-12 col-md-8'>
              <div className='row'>
                <Encabezado logo = {logo} />
              </div>
              <div>
                <MenuP />
              </div>
              <div className='row'>
                <Routes>
                  <Route path='/' element={<InicioProf />} />
                  <Route path='/sermason' element={<Masoneria />} />
                  <Route path='/queesmasoneria' element={<Masoneria />} />
                  <Route path='/historiadelamasoneria' element={<HMasoneria />} />
                  <Route path='/requisitos' element={<Requisitos />} />
                  <Route path='/historiathemis' element={<HThemis />} />
                  <Route path='/login' element={<Login />} />
                </Routes>
              </div>
            </div>
          </div>
        </BrowserRouter>
      );
    }
  else
    {
      return ( 
        <BrowserRouter>
          <div className="App">
            <div className='container col-12 col-md-8'>
              <Encabezado logo = {logo} />
              <MenuP logo = {logo} />
              Adios
              <Routes>
                <Route path='/' element ={<InicioH />} />
                <Route path='/logout' element={<Logout />} />
              </Routes>
            </div>
          </div>
        </BrowserRouter>
      );
    }
}

export default App;
