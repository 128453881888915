import { NavLink } from "react-router-dom";

function Masoneria () {
    return(
        <div className="mt-3">
            <p className="text-primary"><h2>¿Qué es la Masonería?</h2></p>
            <hr />
            <p style={{ textAlign: 'justify'}}>La Masonería es una Institución iniciática, universal, humanista y cultural, que basada en los principios de amor a la humanidad y a la verdad, trabaja para el progreso moral y material de todos los hombres a los que aspira a hermanar por el vínculo de la solidaridad.</p>
 
            <p style={{ textAlign: 'justify'}}>Estimula y practica: </p>
            <   ol>
                    <li>El estudio de la filosofía, de la moral, de las ciencias y de las artes, para mejorar la condición social del hombre y la mujer por todos los medios lícitos y especialmente por la instrucción, el trabajo y la abnegación.</li>
                    <li>La tolerancia, ejercida para hacer más sólidos los lazos de unión entre los semejantes y extinguir los antagonismos de nacionalidad, opiniones, razas e intereses.</li>
                    <li>El libre pensamiento, sin menoscabo de ninguna idea, para evidenciar que es el raciocinio humano el que debe regir los destinos del mundo.</li>
                </ol>
            <p style={{ textAlign: 'justify'}}>
            Considera las concepciones filosóficas y metafísicas como del dominio exclusivo de la apreciación individual de sus miembros y rechaza toda afirmación dogmática.
             
            Tiene por divisa: Libertad, Igualdad y fraternidad.
             
            Algunos historiadores remontan los comienzos de la Masonería al inicio de los tiempos; otros a Egipto y la construcción de las pirámides y la mayoría a la Edad Media, a partir de los gremios y cofradías de constructores y artesanos.
            Sin embargo, la Masonería moderna, nace en Londres en 1717.
            </p>
            <div className="text-start">
                <ol>
                    <li>Ser presentado por integrantes activos de la Gran Logia de la Masonería del Uruguay.</li>
                    <li>Ser mayor de 21 años.</li>
                    <li>Gozar de buena reputación tanto en el ambiente público como el privado.</li>
                    <li>Contar con un trabajo legal que le provea ingresos suficientes para afrontar las obligaciones.</li>
                    <li>Ser ciudadano uruguayo o con dos años de residencia legal.</li>
                    <li>Contar con los tiempos requeridos para su formación y estudio.</li>
                    <li>Contar con el apoyo de su familia para integrar la Masonería. </li>
                </ol>
            </div>
            <NavLink className="button btn-primary" to="/sermason/requisitos">Requisitos</NavLink>
        </div>
    )
}

export default Masoneria;