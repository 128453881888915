

function Requisitos () {
    return(
        <div>
            <p className="text-primary"><h2>Requisitos</h2></p>
            <hr />
            <p style={{ textAlign: 'justify'}}>Los requisitos para integrar la Masonería son los siguientes:</p>
            <div className="text-start">
                <ol>
                    <li>Ser presentado por integrantes activos de la Gran Logia de la Masonería del Uruguay.</li>
                    <li>Ser mayor de 21 años.</li>
                    <li>Gozar de buena reputación tanto en el ambiente público como el privado.</li>
                    <li>Contar con un trabajo legal que le provea ingresos suficientes para afrontar las obligaciones.</li>
                    <li>Ser ciudadano uruguayo o con dos años de residencia legal.</li>
                    <li>Contar con los tiempos requeridos para su formación y estudio.</li>
                    <li>Contar con el apoyo de su familia para integrar la Masonería. </li>
                </ol>
            </div>
        </div>
    )
}

export default Requisitos;