import { NavLink } from "react-router-dom";

function MenuP(){
    return(
        <div className="container">
            <nav className="navbar navbar-expand-lg bg-primary p-2 text-dark bg-opacity-50 rounded">
                <div className="container-fluid">
                    <NavLink className="navbar-brand text-white" to="/historiathemis">Themis</NavLink>
                    <button className="navbar-toggler align-self-end" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <NavLink className='nav-link text-white ' aria-current="page" to="/">Inicio</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link dropdown-toggle text-white" to="/sermason" role="button" data-bs-toggle="dropdown" aria-expanded="false">Ser masón</NavLink>
                                <ul className="dropdown-menu">
                                    <li><NavLink className="dropdown-item" to="/queesmasoneria">¿Qué es la Masonería?</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/historiadelamasoneria">Historia de la Masonería</NavLink></li>
                                    <li><NavLink className='dropdown-item' to='/requisitos'>Requisitos</NavLink></li>
                            </ul>
                            </li>
                            <li className="nav-item">
                                <NavLink className='nav-link text-white ' aria-current="page" to="/login">Login</NavLink>
                            </li>
                        </ul>
                    
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default MenuP;