import { signOut } from "firebase/auth";
import { auth } from '../Firebase';
import { useNavigate } from 'react-router-dom';

function Logout () {
    const navigate = useNavigate();
    const hacerLogout = () => {
        signOut(auth).then(() =>
        {
            console.log ('Me voy');
            navigate('/');
        }).catch ((error) =>{
            console.log ('Hubo un error');
            console.log (error.message);
        })
    }

    return(
        
        <div>
            Usuario: {auth.currentUser.email}
            <button onClick={hacerLogout}> Logout </button>
        </div>
        
    )

}

export default Logout;