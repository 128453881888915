import {initializeApp} from 'firebase/app';
import {getAuth} from 'firebase/auth';

const config = {
  apiKey: "AIzaSyBxLOgkA9yocnnndwc-aGMUcKL1azunSkA",
  authDomain: "themis186-29ad2.firebaseapp.com",
  projectId: "themis186-29ad2",
  storageBucket: "themis186-29ad2.appspot.com",
  messagingSenderId: "218863869296",
  appId: "1:218863869296:web:a018219e7a007fbac703b5",
  measurementId: "G-685270HJSQ"
}

const app = initializeApp(config);

export const auth = getAuth(app);