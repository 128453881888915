import React, { useState, useContext, useEffect } from 'react';
//import {signInWithEmailAndPassword} from 'firebase/auth';
//import {auth} from '../Firebase/firebaseConfig';
import { useNavigate } from 'react-router-dom';
//import { UserContext } from '../MyContext';
import { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { auth } from '../Firebase';

function Login () {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMsg, setErrorMsg] = useState('');

  //  const [errorMsg, setErrorMsg] = useState ('');
    
    const navigate = useNavigate();

   // const datos = useContext(UserContext);
   // console.log (datos);

    const btnSubmit = e => {
        e.preventDefault();

        console.log ('estoy btnSubmit');
        
        signInWithEmailAndPassword(auth, email,password)
        .then((user) =>{
            setEmail('');
            setPassword('');
            setErrorMsg('');
            navigate('/');    
            //props.history.push('/welcome');
        })
        .catch(error => {
            const nuevoMsg = error.message.replace("Firebase", "Themis");
            setErrorMsg(nuevoMsg);
            setEmail('');
            setPassword('');
        })

    }

    const btnCambio = (e) => {
        if (e.target.id === 'email'){
            setEmail (e.target.value);
        } else
        {
            setPassword (e.target.value);
        }
    }


    return(
        <div className='col-6 m-auto mt-3'>
            <div className='row'>
                <h2 className='text-primary'>Ingresar</h2>
            </div>
            <div>
                <span className='text-danger'>{errorMsg}</span>
            </div>
            <form>
                <div className = 'row mt-2'>
                    <div className = 'col-3 text-end'>
                        <label htmlFor='email'>E-mail</label>
                    </div>
                    <div className = 'col-7'>
                        <input type='email' id='email' value={email} onChange={btnCambio} />
                    </div>
                </div>
                <div className = 'row mt-3'>
                    <div className = 'col-3 text-end'>
                        <label htmlFor='password'>Contraseña</label>
                    </div>
                    <div className = 'col-7'>
                        <input type='password' id='password' value={password} onChange={btnCambio} />
                    </div>
                </div>
                    <button type='submit' className='btn btn-primary mt-2' onClick={btnSubmit}>Ingresar</button>
            </form>
        </div>
    )
}

export default Login;