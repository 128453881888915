//import { NavLink } from "react-router-dom";



function HMasoneria () {
    return(
        <div className="mt-3">
            <p className="text-primary"><h2>Historia de la Masonería</h2></p>

            <hr />
            <p style={{ textAlign: 'justify'}}>Hay varias teorías sobre los orígenes de la masonería, que van desde los antiguos constructores hasta los canteros medievales. Todavía hasta el día de hoy, las raíces de la masonería moderna son objeto de intensa especulación, pero el consenso general entre los eruditos masónicos es que se encuentran en los albañiles medievales que construyeron castillos y catedrales.</p>
 
            <p style={{ textAlign: 'justify'}}>Le invitamos a acompañarnos, desde esos remotos orígenes,  a conocer algunos hitos de la historia de la Masonería en Uruguay.</p>
            
            <p style={{ textAlign: 'justify'}}>
            <b>1807</b> - Iniciación del criollo Miguel Furriol en la Logia militar No. 192, instalada por integrantes del 1er. Batallón del Regimiento Irlandés No. 47 de Infantería, el 18 de Julio de 1807
            </p>
            <p style={{ textAlign: 'justify'}}>Las investigaciones históricas no han revelado hasta ahora, la existencia formal de Logias masónicas dentro del territorio, pese a que se mencionan las Logias Tercera Lautaro y de los Diecinueve, formadas por porteños exiliados y oficiales portugueses respectivamente. También se mencionan las Logias de los Independentistas y la posterior de los Caballeros Orientales, formadas por ciudadanos orientales rebeldes a los gobiernos portugués y brasileño.</p>
            <p style={{ textAlign: 'justify'}}>
            <b>1827</b> - Sin embargo, y pese a que hubo actividad masónica durante la dominación luso - brasileña, no sería sino hasta 1827, con la fundación de la Logia "Les Enfants du Nouveau Monde", regularizada por el Gran Oriente de Francia en 1844 con el nombre "Les Amis de la Patrie", que surgiría una verdadera y regular logia masónica en nuestro territorio, con el respaldo formal de algún Oriente reconocido.
            </p>
            <p style={{ textAlign: 'justify'}}>
            <b>1830</b> - Posteriormente, el 21 de Enero de 1830, se funda en instancia la Logia “Asilo de la Virtud”, que solicitó su reconocimiento a la Gran Logia de Pennsylvania, y en Enero de 1831 la Logia “Constante Amistad”, que solicitó el suyo a la Gran Logia de Filadelfia, ante la inactividad del Gran Oriente de Brasil.
            </p>
            <p style={{ textAlign: 'justify'}}>
            <b>1856</b> - Con fecha 7 de octubre de 1854, el H.·. Gabriel Pérez obtuvo del Supremo Consejo y Gran Oriente de Brasil, el encargo de “...fundar, constituir y establecer el (Supremo) Consejo del mismo rito en la República Oriental del Uruguay...”, siendo reconocido al efecto como Soberano Gran Comendador. A partir de entonces, dicho Supremo Consejo encaró la ciclópea tarea de promover la unificación y regularidad de las logias y cuerpos masónicos que funcionaban en el país, lo que se vio coronado por el reconocimiento otorgado por parte del Gran Oriente de Brasil, al Muy Poderoso Supremo Consejo y Gran Oriente del Uruguay como Potencia Masónica, independiente y regular, hecho ocurrido el 17 de julio de 1856.
            </p>
            <p style={{ textAlign: 'justify'}}>
            <b>1864</b> - En su proceso de regularización institucional, el 8 de agosto de 1864, el Supremo Consejo y Gran Oriente del Uruguay, sancionó un Código como Ley Fundamental de la Orden Masónica en la República Oriental del Uruguay, que fue modificado en 1868.
            </p>
            <p style={{ textAlign: 'justify'}}>
            <b>1881</b> - En 1881, el Soberano Gran Comendador, Hermano Carlos de Castro 33º, (1835-1911), abogado y político de extensa actuación, que se desempeñó como Soberano Gran Comendador y Gran Maestro entre 1879 y 1889, y durante cuyo mandato se obtuvo el reconocimiento de la personería jurídica de la Institución, el 11 de mayo de 1882, redactó y logró hacer aprobar una nueva Constitución y Código.
            </p>
            <p style={{ textAlign: 'justify'}}>
            <b>1887</b> - Por desavenencias dentro del Gran Oriente se crea la “Liga Masónica Uruguaya” el 1º de noviembre de 1887. En febrero de 1888, se funda un Supremo Consejo de Soberanos Grandes Inspectores del Grado 33º presidido por el Hermano Prudencio Ellauri y en mayo del mismo año se constituye el Serenísimo Gran Oriente Nacional de la Liga Masónica del Uruguay..
            </p>
            <p style={{ textAlign: 'justify'}}>
            <b>1889</b> - En abril de 1889 comenzaran las gestiones tendientes a la reunificación con la sanción de una ley de amnistía para todos los Hermanos integrantes de la Liga Masónica, que culminarían el 20 de diciembre de ese mismo año con la reincorporación al Serenísimo Gran Oriente y Supremo Consejo del Uruguay y la consiguiente regularización, de todos ellos.
            </p>
            <p style={{ textAlign: 'justify'}}>
            <b>1890</b> - En 1898, y como consecuencia de las dificultades económicas derivadas de la crisis de 1890 y los avatares políticos de la década, el Serenísimo Gran Oriente y Supremo Consejo del Uruguay entró en sueño. Toda la actividad masónica quedó reducida a la desarrollada por la Logia Les Amis de la Patrie (que fundada en 1827, abatiría columnas en 1937), que seguía estando bajo la obediencia del Gran Oriente de Francia, a la que se incorporaron muchos Hermanos de nuestra Obediencia.
            </p>
            <hr />
            <p className="text-center mt-3"><u><h4>Resurgimiento y apogeo del Gran Oriente</h4></u></p>
            <p style={{ textAlign: 'justify'}}>
                Un grupo de Hermanos procuraran el concurso del Hermano Carlos de Castro y lograran la reanudación de los trabajos el 27 de mayo de 1903, dando inicio a un período de esplendor de la Masonería del Uruguay.
            </p>
            <p style={{ textAlign: 'justify'}}>
                Constituidas las nuevas autoridades, una de las primeras decisiones fue la de vender el edificio de las calles Soriano y Paraguay para saldar deudas y la de adquirir un nuevo local, hecho que se concretaría en 1910 con la adquisición de un inmueble en la calle Victoria Nº 87 (hoy Mario Cassinoni 1481), que fue inaugurado el sábado 26 de junio de 1915.
            </p>
            <p style={{ textAlign: 'justify'}}>
                Durante este período, muchos de los integrantes de la Orden se destacaron en la administración pública, en la industria y el comercio y en las artes y las letras, en una activa interacción con el mundo profano que demostraba el compromiso de los Hermanos con los asuntos y los temas de la época.
            </p>
            <p style={{ textAlign: 'justify'}}>
                También le cupo a la Orden una activa participación en los debates de la Asamblea Constituyente que elaboró al Constitución de la República de 1918, no sólo a través de los numerosos Hermanos que la integraron sino a través de notas que el Gran Oriente remitió a la misma fijando posición sobre diversos temas en debate, como por ejemplo el referente al tratamiento a dar a los bienes de la Iglesia.
            </p>
            <p style={{ textAlign: 'justify'}}>
                La elección en 1921 del Hermano Julio Bastos (1863-1929), abogado y magistrado de reconocido prestigio y miembro de la Alta Corte de Justicia entre 1908 y 1929, como Soberano Gran Comendador (cargo que ya había ocupado entre 1906-1908), abrió un período de esplendor de la masonería nacional, marcado por el crecimiento y la vitalidad de la Orden.
            </p>
            <hr />
            <p className="text-center mt-3"><u><h4>La crisis de 1929 y el surgimiento de la Gran Logia de la Masonería del Uruguay</h4></u></p>
            <p style={{ textAlign: 'justify'}}>
                Entre el 29 de abril y el 4 de mayo de 1929, tuvo lugar en París la IV Conferencia Internacional de Supremos Consejos, que entre otras resoluciones, recomendó que los poderes Simbólicos y Filosóficos fueran ejercidos por Potencias diferentes a efectos de lograr la universalidad del Simbolismo. El poder Simbólico tendría jurisdicción sobre los Hermanos de los tres primeros grados, mientras que el Filosófico lo tendría sobre los Hermanos que poseyeran del Grado 4 al 33, siempre que fueran “miembros regulares y asiduos de las Logias. Simbólicas”, siendo su principal cometido el de “conservar, propagar y defender el esoterismo en la Francmasonería.”
            </p>
            <p style={{ textAlign: 'justify'}}>
                Esta indicación fue recogida por un importante número de Hermanos, que además planteó la necesidad de democratizar la vida de la Orden a través de la consagración de institutos propios de los sistemas republicanos democráticos de gobierno, lo que motivó que el 18 de julio de 1930 se realizara una asamblea de masones en la que además de celebrar el primer Centenario de la Masonería uruguaya y de la Jura de la Constitución de la República, se resolvió crear la Gran Logia Masónica del Uruguay, que pronto estableció su sede en la calle Paysandú 889.
            </p>
            <p style={{ textAlign: 'justify'}}>
                Procurando acercar posiciones, en 1931, el Supremo Consejo del Grado 33° con sede en la calle Victoria (hoy Mario Cassinoni) 1481, resolvió delegar en el Serenísimo Gran Oriente del Uruguay, todo lo relacionado con el gobierno y administración de los grados del 1° al 3°, suscribiéndose al efecto el denominado Pacto de Familia y un Tratado de Paz y Alianza con el mismo.
                Esta nueva situación institucional, que recogía –aunque parcialmente- el reclamo de los Hermanos disidentes, ambientó, ya en 1931, el inicio de tratativas tendientes a la reunificación de la Masonería nacional, que se concretaría en 1940 con el surgimiento de la actual “Masonería del Uruguay” (integrada por la Gran Logia de la Masonería del Uruguay y el Supremo Consejo del Rito Escocés Antiguo y Aceptado para la República Oriental del Uruguay), que rápidamente encaró un proceso de ordenamiento institucional y financiero que pronto permitió el normal desenvolvimiento de los trabajos masónicos.
            </p>
            <hr />
            <p className="text-center mt-3"><u><h4>La Confederación Masónica Interamericana</h4></u></p>
            <p style={{ textAlign: 'justify'}}>
                Desde su origen, la Masonería del Uruguay mantuvo una intensa actividad de relacionamiento interpotencial que abonó el prestigio de la masonería uruguaya en el concierto masónico americano y mundial.
            </p>
            <p style={{ textAlign: 'justify'}}>
                Fruto de él y de diversos encuentros interpotenciales preparatorios, tuvo lugar en Montevideo, entre el 14 y el 20 de abril de 1947, la Primera Conferencia Interamericana de la Francmasonería Simbólica, que concluyó con al creación de la Confederación Masónica Interamericana (C.M.I.), organización que agrupa a todas las Grandes Logias Regulares reconocidas como tales y que en los diversos países de América están integradas por personas que se han identificado con el "Ideal Universalista Francmasónico" y la "Declaración de principios de la C.M.I." y han sido admitidas regularmente como sus miembros en cada una de ellas.
            </p>
            <p style={{ textAlign: 'justify'}}>
                Son objetivos de la C.M.I.:
            </p>
            <ol style={{ textAlign: 'justify'}}>
                <li>Promover la unidad y colaboración recíprocas entre todas las Grandes Logias Regulares Confederadas, fomentando el prestigio integral de la Francmasonería.</li>
                <li>Coordinar la acción masónica de las Grandes Logias Confederadas en torno a problemas que les sean comunes, respetando el principio de soberanía y de no intervención en asuntos internos de cada una de ellas.</li>
                <li>Contribuir, con cuantos esfuerzos se realicen a nivel nacional e internacional, a la defensa de la libertad, de los derechos humanos, de la justicia, de la verdad, del mantenimiento de la paz, de la solidaridad, protección de la ecología y de la más sincera colaboración entre los pueblos de América y del resto del mundo.</li>
                <li>Establecer las bases para realizar y mantener, de manera constante y sistemática el fortalecimiento, la consolidación y la conducción de una educación y docencia masónicas que constituyan el medio más eficaz para cumplir con el fin supremo del Ideal Universal Francmasónico.</li>
                <li>Promover la creación de entidades paramasónicas, culturales y humanitarias, cuyos fines y objetivos sean consecuentes con los de la C.M.I.</li>
                <li>Propiciar la creación y mantener el espíritu y la conciencia de la solidaridad, de la fraternidad y de la participación entre las agrupaciones paramasónicas juveniles, femeninas y otras que sean de interés a los objetivos de la C.M.I., incitándolas a incorporarse a todas aquellas otras actividades que tiendan a promover el progreso socioeconómico de sus respectivas comunidades en el proceso de desarrollo.</li>
            </ol>
            <hr />
            <p className="text-center mt-3"><u><h4>La Conferencia Mundial de Grandes Logias</h4></u></p>
            <p style={{ textAlign: 'justify'}}>
                La misma vocación de relacionamiento interpotencial, llevó a la Gran Logia de la Masonería del Uruguay a integrar activamente la Conferencia Mundial de Grandes Logias, creada con el objetivo de brindar un entorno para la reflexión y el intercambio acerca de los fines y propósitos de la Masonería y generar ámbitos de cooperación de la masonería regular.
            </p>
            <p style={{ textAlign: 'justify'}}>Como ejemplo de la incidencia de nuestra Gran Logia, baste con señalar que en 2004, durante la VII Conferencia Mundial de Grandes Logias llevada a cabo en Santiago de Chile, se aprobó por unanimidad declarar el día 24 de Junio de cada año como “Día de la Fraternidad Masónica Universal”.</p>
            <hr />
            <p className="text-center mt-3"><u><h4>La Masonería y la laicidad. La Alianza por la Educación Laica (APEL)</h4></u></p>
            <p style={{ textAlign: 'justify'}}>Fundada por un grupo de hombre y mujeres entre los que destacaban los Hermanos Rodolfo Echeverría y Alfredo Fulle Carrara el 20 de setiembre de 1948 y con personería jurídica reconocida el 8 de octubre de 1951, la APEL surgió para defender los derechos del Hombre y los principios democráticos, defender la laicidad en todas sus manifestaciones y procurar la unificación y la extensión del movimiento laicista.</p>
            <p style={{ textAlign: 'justify'}}>Como su antecesora, la Asociación de Enseñanza Laica, fundada el 1º de febrero de 1907 con el “primordial y principal objeto” de “sostener y difundir, en todo el territorio de la República, la enseñanza científica y racional”, la APEL ha desarrollado desde entonces, con las dificultades que importa la errónea creencia de que las ideas se imponen un día y para siempre, una esforzada labor de concienciación y  defensa de tan caro principio para la Masonería.</p>
            <hr />
            <p className="text-center mt-3"><u><h4>Refugio de la Libertad. El atentado de las fuerzas oscurantistas</h4></u></p>
            <p style={{ textAlign: 'justify'}}>Durante los años de la interrupción democrática, la Masonería del Uruguay cumplió un destacado papel como ámbito de libre expresión del pensamiento.</p>
            <p style={{ textAlign: 'justify'}}>Tal vez por ello, el 23 de diciembre de 1978, sufrió un atentado mediante un artefacto explosivo en el Palacio Masónico de la calle Mario Cassinoni 1481, que causó diversos y serios daños materiales en la fachada e interior del edificio.</p>
            <p style={{ textAlign: 'justify'}}>Si bien nadie reivindicó el hecho, es claro que sus autores pretendieron con su acción amedrentar a quienes, por pertenecer a la Masonería, se han identificado desde siempre con la Libertad, la Igualdad y la Fraternidad.</p>
            <p style={{ textAlign: 'justify'}}>El hecho sirvió además para comprobar, una vez más, el desinteresado apego de los Hermanos a su Gran Logia, ya que apenas enterados del hecho, fueron muchos los que se acercaron y en verdadera y espontánea asamblea de masones, resolvieron, pese a que existían seguros, que serían las Logias y los Hermanos, los que en la medida de sus posibilidades harían frente a los gastos de la reconstrucción.</p>
            <p style={{ textAlign: 'justify'}}>Dicho in suceso no fue obstáculo, sin embargo, para que desde el retorno de la democracia al país en 1985, la Masonería del Uruguay haya procurado la reconciliación de los uruguayos y la concordia entre sus miembros, manteniendo una fluida relación con las autoridades públicas y de otras instituciones del país y colaborando, en la medida de sus posibilidades, en los temas y en los asuntos que hacen a sus objetivos.</p>
            <p style={{ textAlign: 'justify'}}>Fruto de ese compromiso con la libertad, la Gran Logia celebró la aprobación de la Ley 17.778 de fecha 11 de mayo de 2004, por la que se declaró el 20 de setiembre (fecha de la terminación del poder temporal del papado como consecuencia de la caída de la ciudad de Roma en 1870) de cada año como "Día de la Libertad de Expresión de Pensamiento".</p>
            <hr />
            <p className="text-center mt-3"><u><h4>La escisión de 1990</h4></u></p>
            <p style={{ textAlign: 'justify'}}>Divergencias surgidas entre las dos logias de la ciudad de Melo, terminaron tras años de infructuosas gestiones en la fundación de un denominado “Gran Oriente del Uruguay”, que nada tiene que ver con el antecesor directo de la Gran Logia de la Masonería del Uruguay.</p>
            <hr />
            <p className="text-center mt-3"><u><h4>El sesquicentenario. Reafirmación y proyección al futuro</h4></u></p>
            <p style={{ textAlign: 'justify'}}>El 24 de junio de 2005, en un hecho poco frecuente y que marca la importancia del acontecimiento, el  Supremo Consejo del Grado 33° y último del Rito Escocés Antiguo y Aceptado para la República Oriental del Uruguay celebró el sesquicentenario de su fundación en una Tenida Solemne que tuvo lugar en el Gran Templo del Palacio Masónico de Montevideo, de la que participaron todos los Hermanos “escocistas” y varias delegaciones de Potencias extranjeras.
            </p>
            <p style={{ textAlign: 'justify'}}>Los festejos culminaron a fines de ese año con una multitudinaria reunión de confraternidad de la familia masónica en Montevideo. Ambos eventos fueron una oportunidad para reafirmar los lazos y el propósito que anima y justifica la existencia del Supremo Consejo y de las oficinas de su dependencia, llamado a “conservar, propagar y defender el esoterismo en la Masonería.”
            </p>
            <p style={{ textAlign: 'justify'}}>Por su parte, durante el año 2006, la Gran Logia de la Masonería del Uruguay celebró su sesquicentenario con diversos actos y eventos, entre los que se destacaron la ofrenda floral en el monumento al Gral. José Artigas de la Plaza Independencia de Montevideo, el homenaje al Hermano Gabriel Pérez ante el panteón que guarda sus restos en el cementerio Central de Montevideo, el concierto “El Gran Maestro”, obra del Hermano W. A. Mozart, por la Orquesta Filarmónica de Montevideo, dirigida por Stanley De Rusha, en el Teatro Solís, en homenaje a la Masonería del Uruguay en su sesquicentenario y la Tenida Solemne de Gran Logia en el Gran Templo del Palacio Masónico de Montevideo, con la presencia, también, de numerosas delegaciones extranjeras.
            </p>
            <p style={{ textAlign: 'justify'}}>Particular destaque y difusión tuvo también un programa de TV filmado en el Palacio Masónico, que buscó echar luz sobre los principios y objetivos de la Orden.
            </p>
            <p style={{ textAlign: 'justify'}}>El 150 aniversario de la Masonería del Uruguay fue una oportunidad para recordar y revalorizar el pasado pero también para reafirmar principios y objetivos, y fundamentalmente el papel de la Orden y de sus integrantes como actores relevantes de la sociedad que integran.
            </p>
            <div className="mt-5">
                <p className="text-center">
                    <h6>Texto original de www.masoneriadeluruguay.org</h6>
                </p>
            </div>
        </div>
    )
}

export default HMasoneria;