
function HThemis () {
    return(
        <div>
            <h1 className="text-center">Gran Logia de la Masonería del Uruguay</h1>
            <h3 className="text-center">FUNDADA EL 17 DE JULIO DE 1856</h3>
            <br></br>
            <hr />
            <h2 className="text-primary">Logia Themis N<sup><u>ro</u></sup> 186</h2>

            <p style={{ textAlign: 'justify'}}>
                Themis fue fundada en el año 2011. 
            </p>
        </div>
    )
}

export default HThemis;